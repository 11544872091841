'use client';
import PatternCircles from '@/external/components/PatternCircles';
import getCdnImg from '@/shared/cdn/getCdnImg';
import Img from '@/shared/components/Img';

type Props = { site?: Site };

const ErrorBgPattern: FC<Props> = ({ site }) => {
  if (site === 'bitcasino') {
    return (
      <Img
        className="absolute h-full w-full landscape:height2xs:max-h-[414px]"
        src={getCdnImg('Patterns/golden-pattern.png')}
        alt="error-background-pattern"
        fill
      />
    );
  }
  return (
    <PatternCircles className="absolute right-0 top-0 h-1/2 md:h-full w-auto translate-x-[37.5%] opacity-80 md:opacity-100 landscape:height2xs:max-h-[414px]" />
  );
};

export default ErrorBgPattern;
